import React, {useMemo} from "react"
import { Layout } from "../../layouts"
import TopComponent from "../../components/TopComponent/TopComponent"
import "./Projects.scss"
import Contact from "../../components/Contact/Contact"
import ProjectList from "../../components/ProjectList/ProjectList"
import gifImage from "../../assets/images/gif-images/gif-big.svg"
import Pager from "../../components/Pager/Pager"
import { graphql } from "gatsby"
import { MESSAGE_TYPES } from "../../constants"
import SEO from "../../components/Seo"
import { localizeStaticNodes } from "../../utils/localizeDataNodes"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"

const PROJECTS_PER_PAGE = 6

const Projects = ({ pageContext: { pageData, lang, projectImages, companies }, data, location }) => {
  // const { allDzemoApiTops, allDzemoApiProjects } = useMemo(
  //   () => localizeDataNodes(data, lang),
  //   [data, lang]
  // )

  const localizedTops = useMemo(() => localizeStaticNodes(pageData[lang].tops, lang), [pageData, lang])

  return (
    <Layout location={location} lang={lang} pageData={pageData} companies={companies}>
      <SEO
        title={capitalizeFirstLetter(pageData[lang].name)}
        canonical={location.pathname}
        data={pageData[lang]}
      />
      <div className="projects-page">
        <section
          style={{
            display: "flex",
            minHeight: "250px",
          }}
        >
          {localizedTops.length > 0 && (
            <TopComponent data={localizedTops} imageFormatName={"normal"} />
          )}
        </section>

        <div className="gif-content">
          <img src={gifImage} alt="gif" width="414" height="81" />
        </div>

        <section className="projects-wrapper">
          <Pager data={projectImages} itemsPerPage={PROJECTS_PER_PAGE}>
            {pagedData => (
              <ProjectList
                data={pagedData}
                routePrefix={`${lang === "en" ? "" : "/de"}${
                  pageData[lang].path
                }`}
              />
            )}
          </Pager>
        </section>

        <section
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: "250px",
            background: "black",
          }}
        >
          <Contact
            mode="black"
            message={{
              message: "Your message has been sent!",
              type: MESSAGE_TYPES.success,
            }}
          />
        </section>
      </div>
    </Layout>
  )
}

export default Projects

export const query = graphql`
  query projectsPageQuery($pageId: Int!) {
    allDzemoApiTops(filter: { en: { page: { id: { eq: $pageId } } } }) {
      nodes {
        id
        en {
          name
          route
          image {
            alternativeText
            ext
            hash
            height
            id
            name
            size
            url
            width
            formats {
              normal {
                height
                width
                name
                size
                url
              }
              normal_sm {
                height
                width
                name
                size
                url
              }
            }
            caption
          }
          body
        }
        de {
          name
          route
          image {
            alternativeText
            ext
            hash
            height
            id
            name
            size
            url
            width
            formats {
              normal {
                height
                width
                name
                size
                url
              }
              normal_sm {
                height
                width
                name
                size
                url
              }
            }
            caption
          }
          body
        }
      }
    }

    allDzemoApiProjects(
      filter: { en: { featured: { ne: null } } }
      sort: { fields: en___created_at, order: DESC }
    ) {
      nodes {
        en {
          id
          teaserText
          route
          name
          image {
            caption
            alternativeText
            formats {
              project_list {
                height
                width
                url
              }
            }
          }
        }
        de {
          id
          teaserText
          route
          name
          image {
            caption
            alternativeText
            formats {
              project_list {
                height
                width
                url
              }
            }
          }
        }
      }
    }
  }
`
